import LocomotiveScroll from "locomotive-scroll";
class Example {
  constructor(options) {
    this.root = options.root;

    this.init();

    setTimeout(this.showImages.bind(this), 1000);
  }

  init() {
    this.scroll = new LocomotiveScroll({
      el: this.root,
      direction: "horizontal",
      smooth: true,
      lerp: 0.05,
      setHtmlTag: false,
      tablet: {
        smooth: true,
      },

      smartphone: {
        smooth: true,
      },
    });

    this.images = this.root.querySelectorAll(".image");
    console.log("this.images", this.image);
    [].forEach.call(this.images, (image) => {
      image.addEventListener("click", () => {
        image.classList.add("-clicked");
      
        this.hideImages();
      });
    });
  }

  showImages() {
    console.log("show");
    [].forEach.call(this.images, (image) => {
      image.classList.remove("-clicked");
      image.classList.add("-active");
    });
  }

  hideImages() {
    console.log("hide");
    [].forEach.call(this.images, (image) => {
      image.classList.remove("-active");
    });

    setTimeout(this.showImages.bind(this), 2000);
  }
  destroy(){
    this.scroll.destroy()
  } 
}
export default Example;
