<template>
  <div class="show-container">
    <custom-header></custom-header>
    <div class="scroll-animations-example" data-scroll-container>
      <div class="scrollsection" data-scroll-section>
        <!-- "item -normal" "item -big" "item -small -horizontal" "item -small" "item -normal -horizontal" "item -big -horizontal" -->
        <div
          :class="classRandom(1, 4)"
          data-scroll
          :data-scroll-speed="random(1, 4)"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="image" @click="showPreviewModal(item)">
            <img :src="item.coverUrl" />
          </div>
        </div>
      </div>
    </div>
    <custom-footer></custom-footer>
    <preview-modal ref="previewModal"></preview-modal>
  </div>
</template>

<script>
import PreviewModal from "@/components/PreviewModal";
import Example from "@/assets/js/script.js";
import { selectOfficialWebsiteAtlTime } from "@/api/index";
export default {
  name: "login",
  components: {
    PreviewModal,
  },
  data() {
    return {
      list: [],
      classList: [
        "item -normal",
        // "item -big",
        "item -small -horizontal",
        "item -small",
        "item -normal -horizontal",
        // "item -big -horizontal",
      ],
    };
  },
  created() {
    this.selectOfficialWebsiteAtlTime();
  },
  mounted() {},
  methods: {
    showPreviewModal(item) {
      this.$refs.previewModal.show(item.id);
      this.visible = false;
      // if (item.device == 1) {
      //   this.$refs.previewModal.show(item.id);
      // } else {
      //   this.$router.push({
      //     path: "/preview",
      //     query: { id: item.id },
      //   });
      // }
    },
    random(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    classRandom(min, max) {
      let random = this.random(min, max);
      return this.classList[random];
    },

    selectOfficialWebsiteAtlTime() {
      selectOfficialWebsiteAtlTime({
        // isCommend:1,
        isFindCommend:1,
        livingState:1,
        page: { pageNum: 1, pageSize: 100 },
      }).then((res) => {
        console.log(res);
        this.list = res.data.list;
        this.$nextTick(() => {
          this.Example = new Example({
            root: document.querySelector(".scroll-animations-example"),
          });
        });
      });
    },
  },
  destroyed() {
    this.Example.destroy();
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "~@/assets/styles/normalize.min.css";
@import "~@/assets/styles/style.css";
@import "~@/assets/styles/locomotive-scroll.min.css";
.show-container {
  background: url("~@/assets/images/Flashscreenbg.png") no-repeat;
  background-size: 100% 100%;
}
</style>

